import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Input() title: string;
  @Input() resetType: string;
  @Output() sendMail: EventEmitter<any> = new EventEmitter<any>();
  submitted = false;
  resetForm: FormGroup;

  constructor(
    protected ref: NbDialogRef<ForgotPasswordComponent>,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    })
  }

  onSubmit() {
    this.submitted = true;
    Object.keys(this.resetForm.controls).forEach(field => {
      const control = this.resetForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.resetForm.dirty && this.resetForm.valid) {
      const _form = this.resetForm.value;
      this.save(_form);
    }
  }

  dismiss() {
    this.ref.close();
  }

  save(formData) {
    formData['resetType'] = this.resetType;
    console.log(formData);
    this.loginService.sendForgotPasswordEmail({},formData)
    .subscribe((response: any) => {
      if (response.status === 200) {
        this.toastrService.success(response.message);
        this.dismiss();
        this.sendMail.emit(response.data);
      } else if (response.status === 0) {
        this.toastrService.error(response.message);
      }
      else {
        console.log(response);
      }
    });
  }
}
