import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './providers/auth.guard';
import { UserPasswordResetComponent } from './pages/user-password-reset/user-password-reset.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'doctor-login',
        component: LoginComponent,
      },
      {
        path: 'clinic-login',
        component: LoginComponent,
      },
      {
        path: 'lab-login',
        component: LoginComponent,
      },
      {
        path: 'store-login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      /*{
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },*/
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'user-forgot-password',
        component: UserPasswordResetComponent
      },
      {
        path: 'recover-password/:uuid',
        component: RecoverPasswordComponent
      },
      {
        path: 'group-manager-login',
        component: LoginComponent,
      },
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
