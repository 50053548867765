import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationService } from './../services/validation.service';

@Component({
  selector: 'ngx-control-messages',
  template: `<small *ngIf="errorMessage !== null" class="text-danger">{{errorMessage}}</small>`
})
export class ControlMessagesComponent {
  @Input() control: FormControl;
  @Input() fieldName: string;
  constructor() { }

  get errorMessage() {
    // console.log('this.control', this.control);
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return ValidationService.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName],
          this.fieldName
        );
      }
    }

    return null;
  }
}
