import { Component, OnInit } from '@angular/core';
import { NbResetPasswordComponent } from '@nebular/auth';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends NbResetPasswordComponent implements OnInit {

  loginUrl: string = 'login';

  ngOnInit(): void {
    let userData = localStorage.getItem('userdetail');
    let userInfo = JSON.parse(userData);
    //console.log(userInfo);
    if (userInfo.type == 'S' || userInfo.type == 'SA') {
      this.loginUrl = 'store-login';
    }
    else if (userInfo.type == 'D') {
      this.loginUrl = 'doctor-login';
    }
    else if (userInfo.type == 'L' || userInfo.type == 'LA') {
      this.loginUrl = 'lab-login';
    }
    else if (userInfo.type == 'C' || userInfo.type == 'CA') {
      this.loginUrl = 'clinic-login';
    } 
    else if (userInfo.type == 'M') {
      this.loginUrl = 'group-manager-login';
    } 
    else {
      this.loginUrl = 'login';
    }
  }
}
