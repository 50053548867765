import { Component } from '@angular/core';
import { AnalyticsService } from './@core/utils';
import { SeoService } from './@core/utils';

@Component({
  selector: 'ngx-app',
  template: '<app-loading-screen></app-loading-screen><router-outlet></router-outlet>',
})

export class AppComponent {
  title = 'salamtekCMS';

  constructor(
    private analytics: AnalyticsService,
    private seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
  }

}
