import {AbstractControl} from '@angular/forms';

export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, fieldName?: any) {
    const config:any = {
      'required': fieldName + ' is required.',
      'min': 'Invalid value!',
      'max': 'Invalid value!',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Invalid email address',
      'invalidConfirmPassword': 'Invalid confirm password',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `Minimum length ${validatorValue.requiredLength}`
    };

    return config[validatorName];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (!control.value || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  public static matchPassword(control: AbstractControl): { [key: string]: any } | null {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ invalidConfirmPassword: true });
    } else {
      return null;
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

  public static positive(control: AbstractControl):{ [key: string]: any; } {
    if (Number(control.value) < 0) {
      return {isNegative: true};
    } else {
      return null;
    }
  }

  public static timeCrossValidator(control: AbstractControl): { [key: string]: any } | null {

    const start = control.get('startTime').value;
    const end = control.get('endTime').value;

    const startTime = new Date(start);
    const endTime = new Date(end);
    
    if ((control.get('startTime').value && control.get('endTime').value) && startTime >= endTime) {
      control.get('endTime').setErrors({ invalidTime: true });
    } else {
      return null;
    }
  }

  public static contractDateValidator(control: AbstractControl): { [key: string]: any } | null {

    const start = control.get('contractStartDate').value;
    const end = control.get('contractExpiryDate').value;

    const startDate = new Date(start);
    const endDate = new Date(end);
    
    if ((start && end) && startDate >= endDate) {
      control.get('contractExpiryDate').setErrors({ invalidDate: true });
    } else {
      return null;
    }
  }

  public static wholeNumberValidator(control: AbstractControl):{ [key: string]: any; } {
    const number  = Number(control.value);
    if (Math.floor(number) == number) {
      return {notWholeNumber: true};
    } else {
      return null;
    }
  }

}
