import { Component, OnInit } from '@angular/core';
import { User } from '../../@core/data/users';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ValidationService } from '../../services/validation.service';
import { ToastrService } from 'ngx-toastr';
import { LocalSorter } from 'ng2-smart-table/lib/lib/data-source/local/local.sorter';
import { ConfigSettings } from '../../config/config.settings';
import { LoginService } from 'src/app/services/login.service';
import { NbDialogService } from '@nebular/theme';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  hostname: string = '';
  showPassword: boolean = false;
  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private loginService: LoginService,
    private router: Router,
    private toastrService: ToastrService,
    private configSettings: ConfigSettings,
    private dialogService: NbDialogService,
  ) {
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, ValidationService.emailValidator]],
      password: ['', [Validators.required]],
    });
    this.hostname = window.location.hostname;
    console.log(this.hostname);
  }

  togglePassword(e) {
    e.preventDefault();
    this.showPassword = !this.showPassword;
  }

  login(event: Event) {
    this.submitted = true
    Object.keys(this.loginForm.controls).forEach(field => {
      const control = this.loginForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.loginForm.dirty && this.loginForm.valid) {
      try {
        const _form = this.loginForm.value;
        const postParams = {
          email: _form.email,
          password: _form.password,
        };
        if (this.hostname === 'doctor.salamtek.com' || this.hostname === 'doctor-dev.salamtek.com' || this.hostname === 'doctor-stg.salamtek.com' || this.router.url == '/auth/doctor-login') {
          this.loginService.doctorLogin({}, postParams)
            .pipe(first())
            .subscribe(response => {
              if (response.status === 1) {
                this.configSettings.setLoginUserData(response.data.user);
                this.toastrService.success('Logged in');
                window.location.href = '/pages/home/doctor-dashboard/';
              } else if (response.status === 0) {
                this.toastrService.error(response.message);
                this.loginForm.patchValue({
                  email: _form.email,
                  password: '',
                });
              } else {
                console.log(response);
              }
            });
        }
        else if (this.hostname === 'center.salamtek.com' || this.hostname === 'center-stg.salamtek.com' || this.hostname === 'center-dev.salamtek.com' || this.hostname === 'clinic.salamtek.com' || this.hostname === 'clinic-stg.salamtek.com' || this.hostname === 'clinic-dev.salamtek.com' || this.router.url == '/auth/clinic-login') {
          this.loginService.clinicLogin({}, postParams)
            .pipe(first())
            .subscribe(response => {
              if (response.status === 1) {
                this.configSettings.setLoginUserData(response.data.user);
                this.toastrService.success('Logged in');
                window.location.href = '/pages/home/clinic-dashboard/';
              } else if (response.status === 0) {
                this.toastrService.error(response.message);
                this.loginForm.patchValue({
                  email: _form.email,
                  password: '',
                });
              } else {
                console.log(response);
              }
            });
        }
        else if (this.hostname === 'lab.salamtek.com' || this.hostname === 'lab-dev.salamtek.com' || this.hostname === 'lab-stg.salamtek.com' ||this.router.url == '/auth/lab-login') {
          this.loginService.labLogin({}, postParams)
            .pipe(first())
            .subscribe(response => {
              if (response.status === 1) {
                this.configSettings.setLoginUserData(response.data.user);
                this.toastrService.success('Logged in');
                window.location.href = '/pages/home/lab-dashboard/';
              } else if (response.status === 0) {
                this.toastrService.error(response.message);
                this.loginForm.patchValue({
                  email: _form.email,
                  password: '',
                });
              } else {
                console.log(response);
              }
            });
        }
        else if (this.hostname === 'store.salamtek.com' || this.hostname === 'store-dev.salamtek.com' || this.hostname === 'store-stg.salamtek.com' || this.router.url == '/auth/store-login') {
          this.loginService.storeLogin({}, postParams)
            .pipe(first())
            .subscribe(response => {
              if (response.status === 1) {
                this.configSettings.setLoginUserData(response.data.user);
                this.toastrService.success('Logged in');
                window.location.href = '/pages/home/store-dashboard/';
              } else if (response.status === 0) {
                this.toastrService.error(response.message);
                this.loginForm.patchValue({
                  email: _form.email,
                  password: '',
                });
              } else {
                console.log(response);
              }
            });
        }
        else if (this.hostname === 'manager.salamtek.com' || this.hostname === 'manager-dev.salamtek.com' || this.hostname === 'manager-stg.salamtek.com' || this.router.url == '/auth/group-manager-login') {
          this.loginService.managerLogin({}, postParams)
            .pipe(first())
            .subscribe(response => {
              if (response.status === 1) {
                this.configSettings.setLoginUserData(response.data.user);
                this.toastrService.success('Logged in');
                window.location.href = '/pages/home/manager-dashboard/';
              } else if (response.status === 0) {
                this.toastrService.error(response.message);
                this.loginForm.patchValue({
                  email: _form.email,
                  password: '',
                });
              } else {
                console.log(response);
              }
            });
        }
        else {
          this.adminService.login({}, postParams)
            .pipe(first())
            .subscribe(response => {
              if (response.status === 1) {
                // localStorage.setItem('userdetail', JSON.stringify(response.data.user));
                this.configSettings.setLoginUserData(response.data.user);
                // console.log(localStorage.getItem('userdetail'));
                this.toastrService.success('Logged in');
                // this.router.navigate(['/pages/home/']).then(() => {
                //   window.location.reload();
                // });
                window.location.href = '/pages/home/';
              } else if (response.status === 0) {
                this.toastrService.error(response.message);
                this.loginForm.patchValue({
                  email: _form.email,
                  password: '',
                });
              } else {
                console.log(response);
              }
            });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  openForgotPasswordForm() {
    let resetType = '';
    if (this.hostname === 'doctor.salamtek.com' || this.hostname === 'doctor-dev.salamtek.com' || this.hostname === 'doctor-stg.salamtek.com' || this.router.url == '/auth/doctor-login') {
      resetType = 'D';
    } else if (this.hostname === 'center.salamtek.com' || this.hostname === 'center-stg.salamtek.com' || this.hostname === 'center-dev.salamtek.com' || this.hostname === 'clinic.salamtek.com' || this.hostname === 'clinic-stg.salamtek.com' || this.hostname === 'clinic-dev.salamtek.com' || this.router.url == '/auth/clinic-login') {
      resetType = 'C';
    }
    else if (this.hostname === 'lab.salamtek.com' || this.hostname === 'lab-dev.salamtek.com' || this.hostname === 'lab-stg.salamtek.com' ||this.router.url == '/auth/lab-login') {
      resetType = 'L';
    }
    else if (this.hostname === 'store.salamtek.com' || this.hostname === 'store-dev.salamtek.com' || this.hostname === 'store-stg.salamtek.com' || this.router.url == '/auth/store-login') {
      resetType = 'S';
    }
    else if (this.hostname === 'manager.salamtek.com' || this.hostname === 'manager-dev.salamtek.com' || this.hostname === 'manager-stg.salamtek.com' || this.router.url == '/auth/group-manager-login') {
      resetType = 'M';
    }
    else {
      resetType = 'A';
    }
    let dialogRef = this.dialogService.open(ForgotPasswordComponent, {
      context: {
        title: 'Forgot Password',
        resetType: resetType,
      },
    });
    dialogRef.componentRef.instance.sendMail.subscribe((response: any) => {
      console.log(response)
    });
  }

}
