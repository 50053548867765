import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseRoute = 'profile';
  
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  editProfile(getParams?: any, postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/edit-profile', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }
}
