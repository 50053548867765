import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigSettings } from '../config/config.settings';

@Injectable({
  providedIn: 'root'
})
export class MenuitemService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private configSettings: ConfigSettings
  ) { }

  private menuCountItems: Subject<any> = new BehaviorSubject(Object);

  getMenuCountItems(): Observable<any> {
    return this.menuCountItems.asObservable();
  }

  updateLeftMenuCounter(){
    const items = this.configSettings.getMenuCounterItems();
    const itemCounters = JSON.parse(items);
    this.menuCountItems.next(itemCounters);
  }

}
