import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  doctorLogin(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login/doctor-login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  clinicLogin(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login/clinic-login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  labLogin(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login/lab-login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  storeLogin(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login/pharmacy-login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  managerLogin(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login/manager-login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  sendForgotPasswordEmail(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login/forgot-password', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

}
