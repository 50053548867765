import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfigSettings } from '../../../config/config.settings';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { NotificationCounterService } from 'src/app/services/notification-counter.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  notificationCount: number = 0;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  public timerInterval: any;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private configSettings: ConfigSettings,
    private router: Router,
    private homeService: HomeService,
    private notificationCounterService: NotificationCounterService,
  ) { 
    this.homeService.updateNotificationCounter();
    this.notificationCounterService.updateNotificationCounter();
  }

  ngOnInit() {
    this.notificationCounterService.getNotifyCounter().subscribe((data) => {
      if (data && Object.keys(data).length > 0) {
        this.notificationCount = data;
      }
    })
    this.currentTheme = this.themeService.currentTheme;
    this.user = JSON.parse(this.configSettings.getLoginUserData());
    /*this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);*/

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((event) => {
      this.onItemSelection(event.item.title);
    });

    this.timerInterval = setInterval(()=> {
      //console.log('auto fetch activate');
      this.refreshNotifyCount();
    }, 60000);
  }

  refreshNotifyCount(){
    this.homeService.updateNotificationCounter();
    this.notificationCounterService.updateNotificationCounter();
  }

  onItemSelection(title) {
    const userData = this.configSettings.getLoginUserData();
    const userInfo = JSON.parse(userData);
    //console.log(userInfo);
    if (title === 'Log out') {
      // Do something on Log out
      // console.log('Log out Clicked ');
      this.configSettings.removeLoginUserData();
      if (userInfo.type == 'S' || userInfo.type == 'SA') {
        this.router.navigate(['/auth/store-login']);
      }
      else if (userInfo.type == 'D') {
        this.router.navigate(['/auth/doctor-login']);
      }
      else if (userInfo.type == 'L' || userInfo.type == 'LA') {
        this.router.navigate(['/auth/lab-login']);
      }
      else if (userInfo.type == 'C' || userInfo.type == 'CA') {
        this.router.navigate(['/auth/clinic-login']);
      }
      else if (userInfo.type == 'M') {
        this.router.navigate(['/auth/group-manager-login']);
      }
      else {
        this.router.navigate(['/auth/login']);
      }
    } else if (title === 'Profile') {
      // Do something on Profile
      console.log('Profile Clicked ');
      if (userInfo.type == 'S' || userInfo.type == 'SA') {
        this.router.navigate(['/pages/profile/update-pharmacy'])
      }
      else if (userInfo.type == 'D') {
        this.router.navigate(['/pages/profile/update-doctor'])
      }
      else if (userInfo.type == 'L' || userInfo.type == 'LA') {
        this.router.navigate(['/pages/profile/update-lab'])
      }
      else if (userInfo.type == 'C' || userInfo.type == 'CA') {
        this.router.navigate(['/pages/profile/update-clinic'])
      }
      else if (userInfo.type == 'M') {
        this.router.navigate(['/pages/profile/update-group-manager']);
      }
      else {
        this.router.navigate(['/pages/profile'])
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.timerInterval);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
