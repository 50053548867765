<p class="text-center">
  <img src="assets/images/logo.png" width="140px">
</p>
<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<!--<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>-->

<form aria-labelledby="title" [formGroup]="loginForm" (ngSubmit)="login($event)">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput
           fullWidth
           id="input-email"
           pattern=".+@.+\..+"
           placeholder="Email address"
           fieldSize="large"
           autofocus
           name="email" formControlName="email">
    <ng-container *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched && submitted">
      <p class="caption status-danger" *ngIf="loginForm.controls['email'].errors?.required">
        Email is required!
      </p>
      <!--<p class="caption status-danger" *ngIf="loginForm.controls['email'].errors?.pattern">
        Email should be the real one!
      </p>-->
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label mt-2" for="input-password">Password:</label>
      <button type="button" class="btn btn-info btn-sm mb-1 float-right" *ngIf="!showPassword" (click)="togglePassword($event)">
        <i alt="show" class="far fa-eye eye-show"></i>
      </button>
      <button type="button" class="btn btn-info btn-sm mb-1 float-right" *ngIf="showPassword" (click)="togglePassword($event)">
        <i alt="hide" class="far fa-eye-slash eye-hide"></i>
      </button>
      <!--<a class="forgot-password caption-2" routerLink="../request-password">Forgot Password?</a>-->
    </span>
    <input nbInput
           fullWidth
           name="password"
           [type]="showPassword ? 'text' : 'password'"
           id="input-password"
           placeholder="Password"
           fieldSize="large"
           formControlName="password"
    >
    <!-- <i alt="show" class="far fa-eye eye-show" *ngIf="!showPassword" (click)="togglePassword($event)"></i>
    <i alt="hide" class="far fa-eye-slash eye-hide" *ngIf="showPassword" (click)="togglePassword($event)"></i> -->
    <!-- <a *ngIf="!showPassword" (click)="togglePassword($event)" href="javascript:;">Show password</a>
    <a *ngIf="showPassword" (click)="togglePassword($event)" href="javascript:;">Hide password</a> -->
    <ng-container *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched && submitted">
      <p class="caption status-danger" *ngIf="loginForm.controls['password'].errors?.required">
        Password is required!
      </p>
      <!--<p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contain
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>-->
    </ng-container>
  </div>

  <!--<div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe">Remember me</nb-checkbox>
  </div>-->

  <button nbButton fullWidth status="primary" size="large" type="submit">
    Log In
  </button>
</form>

<section class="another-action" aria-label="Reset">
  Forgot password? <a (click)="openForgotPasswordForm()" href="javascript:;"  class="text-link">Reset Password</a>
</section>
