<div class="header-container">
  <div class="logo-container">
    <img id="logoOriginal" src="assets/images/logo_horizontal.png" style="display: none;">
    <img id="QrCode" src="assets/images/invoiceQr.png" style="display: none;">
    <img id="salamtekLogo" src="assets/images/logo.png" style="display: none;">
    <img id="salamtekLogoVector" src="assets/images/salamtek-vector.png" style="display: none;">
    <img id="handShake" src="assets/images/handshake.png" style="display: none;">
    <img id="whiteImage" src="assets/images/white.png" style="display: none;">
    <img id="stampImage" src="assets/images/stamp.png" style="display: none;">
    <img id="orderProcess3" src="assets/images/Process.svg" style="display: none;">
    <img id="orderProcess1" src="assets/images/one-grey.svg" style="display: none;">
    <img id="orderProcess2" src="assets/images/two-grey.svg" style="display: none;">
    <img id="placeHolderImg" src="assets/images/photo-placeholder.png" style="display: none;">

    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img id="logo" src="assets/images/logo_horizontal.png">
    </a>
  </div>
  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>-->
</div>

<div class="header-container">
  <nb-actions size="small">

    <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>-->
    <nb-action class="control-item header-bell" icon="bell-outline" [badgeText]="notificationCount" badgeStatus="danger"
      link="/pages/notifications/index">
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>