import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigSettings } from '../config/config.settings';
import { MenuitemService } from './menuitem.service';
import { NotificationCounterService } from './notification-counter.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private baseRoute = 'home';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private configSettings: ConfigSettings,
    private menuitemService: MenuitemService,
    private notificationCounterService: NotificationCounterService,
  ) { }


  menuCounterStatistics(getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/menucount-statistics', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  notifyCounterStatistics(getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/notifycount-statistics', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  updateNotificationCounter() {
    this.notifyCounterStatistics()
      .pipe(first())
      .subscribe(response => {
        const data = response.body.data;
        //console.log(data);
        this.configSettings.setNotifyCounter(data);
        this.notificationCounterService.updateNotificationCounter();
      })
  }

  updateLeftMenuCounter() {
    this.menuCounterStatistics()
      .pipe(first())
      .subscribe(response => {
        const data = response.body.data;
        let menuCounterData = {}

        if (data.orderByStatus) {
          menuCounterData = {
            ...menuCounterData,
            orders: {
              pending: data.orderByStatus.pending,
              underProgress: data.orderByStatus.underProgress,
              inProgress: data.orderByStatus.inProgress,
              readyDelivery: data.orderByStatus.readyDelivery,
              outDelivery: data.orderByStatus.outDelivery,
              delivered: data.orderByStatus.delivered,
              cancelled: data.orderByStatus.cancelled,
              readyPickup: data.orderByStatus.readyPickup,
              failed: data.orderByStatus.failed,
              insurance: data.orderByStatus.insurance,
              approvedInsurance: data.orderByStatus.approvedInsurance,
              rejectedInsurance: data.orderByStatus.rejectedInsurance,
              abandonedCart: data.orderByStatus.abandonedCart,
              shopPending: data.orderByStatus.shopPending,
              shopAccepted: data.orderByStatus.shopAccepted,
              shopInprogress: data.orderByStatus.shopInprogress,
              shopReadyDelivery: data.orderByStatus.shopReadyDelivery,
              shopReadyPickup: data.orderByStatus.shopReadyPickup,
              shopOutDelivery: data.orderByStatus.shopOutDelivery,
              shopDelivered: data.orderByStatus.shopDelivered,
              shopCancelled: data.orderByStatus.shopCancelled,
              shopInsuranceOrder: data.orderByStatus.shopInsuranceOrder,
              
            }
          }
        }

        if (data.doctorAppointmentsByStatus) {
          menuCounterData = {
            ...menuCounterData,
            doctorAppointments: {
              upcoming: data.doctorAppointmentsByStatus.upcoming,
              started: data.doctorAppointmentsByStatus.started,
              completed: data.doctorAppointmentsByStatus.completed,
              notCompleted: data.doctorAppointmentsByStatus.notCompleted,
              failed: data.doctorAppointmentsByStatus.failed,
              cancelled: data.doctorAppointmentsByStatus.cancelled,
              noShow: data.doctorAppointmentsByStatus.noShow,
              noShowUser: data.doctorAppointmentsByStatus.noShowUser,
              insurance: data.doctorAppointmentsByStatus.insurance,
            }
          }
        }

        if (data.labAppointmentsByStatus) {
          menuCounterData = {
            ...menuCounterData,
            labAppointments: {
              upcoming: data.labAppointmentsByStatus.upcoming,
              started: data.labAppointmentsByStatus.started,
              completed: data.labAppointmentsByStatus.completed,
              notCompleted: data.labAppointmentsByStatus.notCompleted,
              failed: data.labAppointmentsByStatus.failed,
              cancelled: data.labAppointmentsByStatus.cancelled,
              noShow: data.labAppointmentsByStatus.noShow,
              walkin: data.labAppointmentsByStatus.walkin,
              insurance: data.labAppointmentsByStatus.insurance,
            }
          }
        }

        if (data.walkinAppointmentByStatus) {
          menuCounterData = {
            ...menuCounterData,
            walkinLabAppointments: {
              upcoming: data.walkinAppointmentByStatus.upcoming,
              completed: data.walkinAppointmentByStatus.completed,
              started: data.walkinAppointmentByStatus.started
            }
          }
        }

        if (data.adminApprovals) {
          menuCounterData = {
            ...menuCounterData,
            adminApprovals: {
              clinics: data.adminApprovals.clinics,
              branches: data.adminApprovals.branches,
              doctors: data.adminApprovals.doctors,
              labs: data.adminApprovals.labs,
              pharmacies: data.adminApprovals.pharmacies,
            }
          }
        }

        if (data.customerRequests) {
          menuCounterData = {
            ...menuCounterData,
            customerRequests: data.customerRequests,
            customerRequestsCount:{
              pending: data.customerRequests.pending,
              completed: data.customerRequests.completed,
              noAnswer: data.customerRequests.noAnswer,
              sentToCustomerService: data.customerRequests.sentToCustomerService,
            }


          }
        }

        if (data.partnerRequests) {
          menuCounterData = {
            ...menuCounterData,
            partnerRequests: data.partnerRequests
          }
        }

        if (data.contactUs) {
          menuCounterData = {
            ...menuCounterData,
            contactUs: data.contactUs
          }
        }

        if (data.callRequests) {
          menuCounterData = {
            ...menuCounterData,
            callRequestsCount: {
              notContacted: data.callRequests.notContacted,
              contacted: data.callRequests.contacted
            }
          }
        }
        this.configSettings.setMenuCounterItems(menuCounterData);
        this.menuitemService.updateLeftMenuCounter();
      })
  }

  getOrderCountByStatus(getParams) {
    return this.configService.readRequest(this.baseRoute + '/order-count-status', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getOrderCountByDate(getParams) {
    return this.configService.readRequest(this.baseRoute + '/order-count-date', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getOrderCountByDevice(getParams) {
    return this.configService.readRequest(this.baseRoute + '/order-count-device', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getOrderCountByDoctor(getParams) {
    return this.configService.readRequest(this.baseRoute + '/order-count-doctor', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getPharmacyOrderCountByStatus(getParams) {
    return this.configService.readRequest(this.baseRoute + '/pharmacy-order-count-status', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getPharmacyOrderCountByDate(getParams) {
    return this.configService.readRequest(this.baseRoute + '/pharmacy-order-count-date', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getDoctorLabClinicCount(getParams) {
    return this.configService.readRequest(this.baseRoute + '/count-doctor-lab-clinic', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getAppointmentCount(getParams) {
    return this.configService.readRequest(this.baseRoute + '/appointment-count', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getUserCount(getParams) {
    return this.configService.readRequest(this.baseRoute + '/user-count', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getMostBookedAppointments(getParams) {
    return this.configService.readRequest(this.baseRoute + '/most-booked-appointment', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getTopSoldProduct(getParams) {
    return this.configService.readRequest(this.baseRoute + '/top-sold-product', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getMostSpendingCustomer(getParams) {
    return this.configService.readRequest(this.baseRoute + '/most-spending-customer', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getHighestSellingPharma(getParams) {
    return this.configService.readRequest(this.baseRoute + '/most-selling-pharma', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getPharmaEarning(getParams) {
    return this.configService.readRequest(this.baseRoute + '/pharmacy-earning', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getBookingCountByStatus(getParams) {
    return this.configService.readRequest(this.baseRoute + '/doctor-booking-count-status', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getBookingCountByDate(getParams) {
    return this.configService.readRequest(this.baseRoute + '/doctor-booking-count-date', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getBookingCountByDevice(getParams) {
    return this.configService.readRequest(this.baseRoute + '/doctor-booking-count-device', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getWalkPatientState(getParams) {
    return this.configService.readRequest(this.baseRoute + '/walk-patient-state', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getDoctorCommission(getParams) {
    return this.configService.readRequest(this.baseRoute + '/doctor-commission', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getLabBookingCountByStatus(getParams) {
    return this.configService.readRequest(this.baseRoute + '/lab-booking-count-status', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getLabBookingCountByDate(getParams) {
    return this.configService.readRequest(this.baseRoute + '/lab-booking-count-date', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getLabBookingCountByDevice(getParams) {
    return this.configService.readRequest(this.baseRoute + '/lab-booking-count-device', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getClinicDoctorBookingCountByStatus(getParams) {
    return this.configService.readRequest(this.baseRoute + '/clinic-doctor-booking-count-status', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getClinicDoctorBookingCountByDate(getParams) {
    return this.configService.readRequest(this.baseRoute + '/clinic-doctor-booking-count-date', getParams)
      .pipe(map(response => {
        return response;
      }));
  }
  
  getClinicDoctorBookingCountByDevice(getParams) {
    return this.configService.readRequest(this.baseRoute + '/clinic-doctor-booking-count-device', getParams)
      .pipe(map(response => {
        return response;
      }));
  }
  
  getClinicLabBookingCountByStatus(getParams) {
    return this.configService.readRequest(this.baseRoute + '/clinic-lab-booking-count-status', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getClinicLabBookingCountByDate(getParams) {
    return this.configService.readRequest(this.baseRoute + '/clinic-lab-booking-count-date', getParams)
      .pipe(map(response => {
        return response;
      }));
  }
  
  getClinicLabBookingCountByDevice(getParams) {
    return this.configService.readRequest(this.baseRoute + '/clinic-lab-booking-count-device', getParams)
      .pipe(map(response => {
        return response;
      }));
  }
  
  getManagerLabAppointmentStats(getParams) {
    return this.configService.readRequest(this.baseRoute + '/manager-lab-appointment-stats', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getManagerCountLabClinic(getParams) {
    return this.configService.readRequest(this.baseRoute + '/manager-count-lab-clinic', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getNotification(getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/get-notification', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

}
