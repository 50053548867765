import { Injectable } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {map} from 'rxjs/operators';
import {ServerDataSource} from 'ng2-smart-table';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private baseRoute = 'admin';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  getGridData(listUrl, pageSize, pageNumber) {
    return new ServerDataSource(this.http,
      {
        dataKey: 'data',
        endPoint: listUrl,
        totalKey: 'total'
      });
  }

  getAll(getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/index', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getById(id: number, getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/view/' + id, getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  create(getParams?: any, postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/create', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  update(id: number, getParams?: any, postParams?: any) {
    return this.configService.putRequest(this.baseRoute + '/update/' + id, getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  delete(id: number, getParams?: any) {
    return this.configService.deleteRequest(this.baseRoute + '/delete/' + id, getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  login(getParams?: any, postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  toggleActive(id: number, getParams?: any, postParams?: any) {
    return this.configService.putRequest(this.baseRoute + '/toggle-active/' + id, getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getPermissionItem(id: number, getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/get-permissible-item/'+id, getParams)
      .pipe(map(response => {
        return response;
      }));
  }

}
