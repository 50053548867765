import { Injectable } from '@angular/core';
import { HttpClient } from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { ConfigSettings } from '../config/config.settings';

@Injectable({
  providedIn: 'root'
})
export class NotificationCounterService {

  constructor(
    private configService: ConfigService,
    private configSettings: ConfigSettings
  ) { }

  private notificationCount: Subject<any> = new BehaviorSubject(Object);

  getNotifyCounter(): Observable<any> {
    return this.notificationCount.asObservable();
  }

  updateNotificationCounter(){
    const items = this.configSettings.getNotifyCounter();
    const itemCounters = JSON.parse(items);
    this.notificationCount.next(itemCounters);
  }

}
