<nb-card>
    <nb-card-header>{{title}}</nb-card-header>
    <nb-card-body>
      <form [formGroup]="profileForm" (ngSubmit)="save($event)">
        <div class="row" *ngIf="isAdminLogin">
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Name</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="name" formControlName="name"
                placeholder="">
  
              <ngx-control-messages [control]="profileForm.get('name')" [fieldName]="'Name'"></ngx-control-messages>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Email</label>
              <input type="email" nbInput fullWidth class="form-control text-input" name="email" formControlName="email"
                placeholder="">
              <ngx-control-messages [control]="profileForm.get('email')" [fieldName]="'Email'"></ngx-control-messages>
              <!-- todo: try to manage server side error messages using service class -->
              <small class="text-danger" *ngIf="profileForm.controls['email'].hasError('dupicateEmail')">
                Admin with this email already exist!
              </small>
            </div>
          </div>
  
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" nbInput fullWidth class="form-control text-input" name="password"
                formControlName="password" placeholder="">
              <ngx-control-messages [control]="profileForm.get('password')" [fieldName]="'Password'"></ngx-control-messages>
            </div>
          </div>
  
          <ng-container *ngIf="!id">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="control-label">Confirm Password</label>
                <input type="password" nbInput fullWidth class="form-control text-input" name="confirmPassword"
                  formControlName="confirmPassword" placeholder="">
                <ngx-control-messages [control]="profileForm.get('confirmPassword')" [fieldName]="'Confirm Password'">
                </ngx-control-messages>
              </div>
            </div>
          </ng-container>
  
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label"> Phone </label>
              <input formControlName="phone" type="text" class="form-control" id="phone" name="phone" />
              <ngx-control-messages [control]="profileForm.get('phone')" [fieldName]="'Phone'"></ngx-control-messages>
              <small class="text-danger" *ngIf="profileForm.controls['phone'].hasError('pattern')">Invalid Phone</small>
            </div>
          </div>
  
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Image</label>
              <input type="file" nbInput fullWidth class="text-input" name="image" formControlName="image"
                (change)="onFileChange($event)">
              <ngx-control-messages [control]="profileForm.get('image')" [fieldName]="'Image'"></ngx-control-messages>
            </div>
            <img [src]="imageSrc" *ngIf="imageSrc" style="max-width:150px">
          </div>
        </div>

        <div class="row" *ngIf="isShopLogin">
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Name in English</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="nameEn" formControlName="nameEn"
                placeholder="">
              <ngx-control-messages [control]="profileForm.get('nameEn')" [fieldName]="'Name in English'">
              </ngx-control-messages>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Name in Arabic</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="nameAr" formControlName="nameAr"
                placeholder="">
              <ngx-control-messages [control]="profileForm.get('nameAr')" [fieldName]="'Name in Arabic'">
              </ngx-control-messages>
            </div>
          </div>
  
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Description in English</label>
              <textarea nbInput fullWidth class="form-control text-input" name="descriptionEn" formControlName="descriptionEn"></textarea>
              <ngx-control-messages [control]="profileForm.get('descriptionEn')" [fieldName]="'Description in English'">
              </ngx-control-messages>
            </div>
          </div>
  
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Description in Arabic</label>
              <textarea nbInput fullWidth class="form-control text-input" name="descriptionAr" formControlName="descriptionAr"></textarea>
              <ngx-control-messages [control]="profileForm.get('descriptionAr')" [fieldName]="'Description in Arabic'">
              </ngx-control-messages>
            </div>
          </div>
  
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Logo (300 <span>&#215;</span> 300)</label>
              <input type="file" nbInput fullWidth class="text-input" name="logo" formControlName="logo"
                (change)="onFileChange($event)">
              <ngx-control-messages [control]="profileForm.get('logo')" [fieldName]="'Logo'"></ngx-control-messages>
            </div>
            <img [src]="imageSrc" *ngIf="imageSrc" style="max-width:150px">
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Banner (960 <span>&#215;</span> 390)</label>
              <input type="file" nbInput fullWidth class="text-input" name="background" formControlName="background"
                (change)="onFileChange($event)">
              <ngx-control-messages [control]="profileForm.get('background')" [fieldName]="'Background'">
              </ngx-control-messages>
            </div>
            <img [src]="backgroundSrc" *ngIf="backgroundSrc" style="max-width:150px">
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Contact Number</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="phone" formControlName="phone"/>
              <ngx-control-messages [control]="profileForm.get('phone')" [fieldName]="'Phone'">
              </ngx-control-messages>
              <span class="text-danger form-error"
                *ngIf="profileForm.controls['phone'].hasError('pattern')">Your phone can only contain numeric characters with +/-</span>
            </div>
          </div>
  
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Name of POC</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="pocName" formControlName="pocName"/>
              <ngx-control-messages [control]="profileForm.get('pocName')" [fieldName]="'Name of POC'">
              </ngx-control-messages>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" nbInput fullWidth class="form-control text-input" name="password"
                formControlName="password" placeholder="">
              <ngx-control-messages [control]="profileForm.get('password')" [fieldName]="'Password'"></ngx-control-messages>
            </div>
          </div>

        </div>

        <div class="row" *ngIf="isShopAdminLogin">
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Name in English</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="nameEn" formControlName="nameEn"
                placeholder="">
              <ngx-control-messages [control]="profileForm.get('nameEn')" [fieldName]="'Name in English'">
              </ngx-control-messages>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Name in Arabic</label>
              <input type="text" nbInput fullWidth class="form-control text-input" name="nameAr" formControlName="nameAr"
                placeholder="">
              <ngx-control-messages [control]="profileForm.get('nameAr')" [fieldName]="'Name in Arabic'">
              </ngx-control-messages>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" nbInput fullWidth class="form-control text-input" name="password"
                formControlName="password" placeholder="">
              <ngx-control-messages [control]="profileForm.get('password')" [fieldName]="'Password'"></ngx-control-messages>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="form-group col-12">
            <button type="submit" class="btn btn-success">
              Save
            </button>
          </div>
        </div>

      </form>
    </nb-card-body>
  </nb-card>