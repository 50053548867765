import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { ConfigSettings } from '../config/config.settings';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js'
import { environment } from 'src/environments/environment';
import { LoadingScreenService } from '../services/loading-screen.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

  activeRequests: number = 0;
  skippUrls = [
    '/home/get-notification',
    '/home/menucount-statistics',
    '/home/notifycount-statistics'
  ];

  constructor(
    private configSettings: ConfigSettings,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private toastrService: ToastrService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userData = this.configSettings.getLoginUserData();
    const userToken = JSON.parse(userData);
    let authReq: any = {};
    if (userData && userToken.token !== undefined) {
      //console.log(userToken);
      let userId = parseInt(userToken.id).toString();
      let userType = userToken.type;
      let currencyId = parseInt(userToken.currencyId).toString();
      const secret = this.getHeaderKey();
      authReq = req.clone({
        headers: req.headers.set('auth', userToken.token).set('secret', secret)
          .set('usertype', userType)
          .set('userid', userId)
          .set('currency', currencyId)
          .set('Access-Control-Allow-Origin', '*')
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set('auth', '').set('secret', '').set('usertype', '').set('userid', '').set('currency', '').set('Access-Control-Allow-Origin', '*')
      });
    }

    let displayLoadingScreen = true;
    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(authReq.url)) {
        displayLoadingScreen = false;
        break;
      }
    }
    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.activeRequests++;
      return next.handle(authReq).pipe(tap(() => {
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 403) {
            //console.log(err.error.message);
            this.toastrService.error(err.error.message);
          }
          if (err.status !== 401) {
            return;
          }
          this.router.navigate(['/auth/login']);
        }
      }),
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      )
    } else {
      return next.handle(authReq);
    }

  }

  getHeaderKey() {
    let d = Date.now();
    const timeStamp = new Date(d + 300000).toISOString();
    const authType = 'cms';
    const key = timeStamp + '###' + authType;
    return this.aesEncrypt(key);
  }

  aesEncrypt(data) {
    let iv = environment.IV;
    let key = environment.ENC_KEY;
    let cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return cipher.toString();
  }

}
