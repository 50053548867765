import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getDeepFromObject, NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  uuid: string = '';
  loginUrl: string = '';
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private _route: ActivatedRoute,
  ) {
    this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
    this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
    this.strategy = this.getConfigValue('forms.resetPassword.strategy');
  }

  ngOnInit(): void {
    this.uuid = this._route.snapshot.paramMap.get('uuid');
  }

  resetPass(): void {
    this.errors = this.messages = [];
    this.submitted = true;
    this.user['token'] = this.uuid;
    this.service.resetPassword(this.strategy, this.user)
      .subscribe((result: any) => {
        //console.log(result);
        this.submitted = false;
        if (result.isSuccess()) {
          this.messages = result.getMessages();          
          //console.log(result.response.body.data.url)
          this.loginUrl = result.response.body.data.url;
          let redirectUrl = '/auth/'+result.response.body.data.url;
          if (redirectUrl) {
            setTimeout(() => {
              return this.router.navigate([redirectUrl])
            }, 3000);
          }
        } else {
          this.errors = result.getErrors();
        }      
        this.cd.detectChanges();
      });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

}
