import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { first } from 'rxjs/operators';
import { Admin } from '../../models/admin';
import { ConfigSettings } from '../../config/config.settings';
import { ProfileService } from 'src/app/services/profile.service';
@Component({
  selector: 'app-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.scss']
})
export class UserPasswordResetComponent implements OnInit {

  imageSrc: string;
  admin: any;
  profileForm: FormGroup;
  id: number;
  title: string;
  userDetail: any;
  isAdminLogin = true;
  isShopLogin = true;
  isShopAdminLogin = true;
  shopId = null;
  shopAdminId = null;
  backgroundSrc: string;

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private profileService: ProfileService,
    private router: Router,
    private _route: ActivatedRoute,
    private toastrService: ToastrService,
    private configSettings: ConfigSettings,
  ) { }

  ngOnInit(): void {
    this.title = 'Profile';
    let userData = this.configSettings.getLoginUserData();
    let userInfo = JSON.parse(userData);
    this.userDetail = userInfo;
    //console.log(this.userDetail);
    this.isShopLogin = false;
    this.isShopAdminLogin = false;

    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      phone: ['', [Validators.required, Validators.pattern(/^\-?\d+((\.|\,)\d+)?$/)]],
      password: [''],
      confirmPassword: [''],
      image: [''],
      fileSource: [''],
    });
    this.getUpdateData();
  }


  getUpdateData() {
    this.adminService.getById(this.userDetail.id)
      .pipe(first())
      .subscribe(response => {
        const _data = response.body.data;
        if (_data) {
          this.admin = <Admin>_data;
          this.profileForm.patchValue({
            name: this.admin.name,
            email: this.admin.email,
            phone: this.admin.phone,
            password: this.admin.password,
          });
          this.imageSrc = this.admin.image;
        }
      });
  }

  save(event: Event) {
    Object.keys(this.profileForm.controls).forEach(field => {
      const control = this.profileForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.profileForm.valid) {
      try {
        const _form = this.profileForm.value;
        this.profileService.editProfile({}, _form)
          .pipe(first())
          .subscribe(response => {
            //console.log('response', response);
            let user = response.data;
            if (response.status === 1) {
              this.toastrService.success('Profile updated successfully!');
            } else if (response.status === 0) {
              if (response.errorField === 'email') {
                this.profileForm.controls['email'].setErrors({ 'dupicateEmail': true });
              }
            } else {
              console.log(response);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
  onFileChange(event) {
    const fileInputName = event.target.name;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (fileInputName === 'image') {
          this.imageSrc = reader.result as string;
          this.profileForm.patchValue({
            fileSource: reader.result,
          });
        }
        if (fileInputName === 'logo') {
          this.imageSrc = reader.result as string;
          this.profileForm.patchValue({
            fileSource: reader.result,
          });
        }
        if (fileInputName === 'background') {
          this.backgroundSrc = reader.result as string;
          this.profileForm.patchValue({
            backgroundSource: reader.result,
          });
        }
      };

    }
  }

}
