<nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12 col-lg-12">
                    <div class="form-group required">
                        <label class="control-label">Email</label>
                        <input type="email" nbInput fullWidth class="form-control text-input" name="email"
                            formControlName="email" placeholder="">
                        <small class="text-danger" *ngIf="submitted && resetForm.controls['email'].hasError('required')">
                            E-mail is required
                        </small>
                        <small class="text-danger" *ngIf="submitted && (resetForm.controls['email'].hasError('email') || resetForm.controls['email'].hasError('pattern'))">
                            E-mail must be a valid email address
                        </small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <button type="submit" class="btn btn-success">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>