import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigSettings {

  @Output() load: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) {
  }

  setLoginUserData(user: any) {
    localStorage.setItem('userdetail', JSON.stringify(user));
  }

  getLoginUserData(): any {
    return localStorage.getItem('userdetail');
  }

  removeLoginUserData(): any {
    localStorage.removeItem('userdetail');
    localStorage.removeItem('cart');
    localStorage.removeItem('menuCounterItems');
  }

  setCartData(cart: any) {
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  getCartData() {
    return localStorage.getItem('cart');
  }

  removeCartData(): any {
    localStorage.removeItem('cart');
  }

  cleanUrl(url: string) {
    return url.trim().toLowerCase().replace(/[^a-zA-Z\d\s]/, '').replace(' ', '-');
  }

  setMenuCounterItems(data: any) {
    localStorage.setItem('menuCounterItems', JSON.stringify(data));
  }

  getMenuCounterItems() {
    return localStorage.getItem('menuCounterItems');
  }

  setNotifyCounter(data: any) {
    localStorage.setItem('_notifyCounter', JSON.stringify(data));
  }

  getNotifyCounter() {
    return localStorage.getItem('_notifyCounter');
  }

  getBrowserVersion() {
    let ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return M.join(' ');
  }
}
